import React, { useState } from "react";
/* Different kinds of columns */

export function IdColumn({ field }) {
  return <th>ID</th>;
}

export function Column({ field, title }) {
  return <th>{title}</th>;
}

export function CheckboxColumn({ field, title }) {
  return <th>{title}</th>;
}

export function ActionColumn({ children }) {
  return <th>Actions</th>;
}

export function Action({ callback, content }) {}

/* Table generation code */

function ColumnHeadings({ field, order, filter }) {
  const incrementState = () => {
    const newState = order.state == "desc" ? "asc" : "desc";
    order.update(field, newState);
  };

  return (
    <th>
      {order !== null && (
        <button
          onClick={() => incrementState()}
          style={{
            width: "25px",
            border: "none",
            background: "none",
            display: "flex-row",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <img
            src={`${labfinderParams.pluginPath}assets/sort-${
              order.state != null ? order.state : "default"
            }.svg`}
          />
        </button>
      )}
      {filter !== null &&
        (filter.type !== "checkbox" ? (
          <input
            type="text"
            value={filter.value}
            onChange={(e) => filter.update(field, e.target.value)}
            style={{
              width: filter.type === "id" ? "50px" : "inherit",
            }}
          />
        ) : (
          <input
            type="checkbox"
            checked={filter.value}
            onChange={(e) => filter.update(field, e.target.checked ? true : "")}
          />
        ))}
    </th>
  );
}

function TableBody({ data, columns }) {
  let rows = data.map((row) => {
    let rowCells = React.Children.map(columns, (child) => {
      if (child.type === IdColumn || child.type === Column) {
        return <td>{row[child.props.field]}</td>;
      } else if (child.type === CheckboxColumn) {
        return <td>{row[child.props.field] ? <>&#10003;</> : <>×</>}</td>;
      } else if (child.type === ActionColumn) {
        let actionButtons = React.Children.map(
          child.props.children,
          (action) => {
            return (
              <button onClick={() => action.props.callback(row)}>
                {action.props.content(row)}
              </button>
            );
          },
        );
        return <td>{actionButtons}</td>;
      }
    });
    return <tr>{rowCells}</tr>;
  });
  return <tbody>{rows}</tbody>;
}

export function Table({ children, data, orderInfo, filterInfo, headerUpdate }) {
  let headings = React.Children.map(children, (child) => {
    return (
      <ColumnHeadings
        field={child.props.field}
        order={
          child.type === ActionColumn || child.type === CheckboxColumn
            ? null
            : {
                state:
                  orderInfo.field === child.props.field
                    ? orderInfo.state
                    : null,
                update: (field, state) => headerUpdate("order", field, state),
              }
        }
        filter={
          child.type === ActionColumn
            ? null
            : {
                value:
                  filterInfo.field === child.props.field
                    ? filterInfo.value
                    : "",
                type:
                  child.type === CheckboxColumn
                    ? "checkbox"
                    : child.type === IdColumn
                    ? "id"
                    : "text",
                update: (field, value) => headerUpdate("filter", field, value),
              }
        }
      />
    );
  });

  return (
    <table className="widefat" style={{ width: "90%" }}>
      <thead>
        <tr>{children}</tr>
        <tr>{headings}</tr>
      </thead>
      <TableBody data={data} columns={children} />
    </table>
  );
}
