import { Fragment } from "react";
import { useState, useEffect } from "@wordpress/element";
import { API } from "utils";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import { Loader } from "@googlemaps/js-api-loader";

class LabMap extends React.Component {
  constructor(props) {
    super(props);
    this.map = null;
  }

  static style = {
    minHeight: "600px",
    width: "100%",
  };

  static default_view = {
    center: { lat: 47, lng: 20.56 },
    zoom: 7,
  };

  componentDidMount() {
    const loader = new Loader({
      apiKey: "AIzaSyDKHE0_TXlWSjlIu1-kLHiRXlS0BnXKZmU",
      version: "weekly",
      libraries: ["places"],
    });

    loader.importLibrary("maps").then(async ({ Map }) => {
      let map = new Map(document.getElementById("map"), {
        ...LabMap.default_view,
        mapId: "b28baf87bfe6eef2",
        fullscreenControl: false,
        streetViewControl: false,
        rotateControl: false,
        mapTypeControl: false,
      });

      let inactive_holder = document.createElement("div");
      inactive_holder.style.backgroundColor = "white";
      inactive_holder.style.padding = "10px";
      inactive_holder.style.position = "absolute";
      inactive_holder.style.right = "10px";
      inactive_holder.style.top = "10px";
      let img = document.createElement("img");
      img.src = labfinderParams.pluginPath + "assets/layers.png";
      inactive_holder.appendChild(img);
      let inactive_form_holder = document.createElement("div");
      inactive_form_holder.style.display = "none";
      inactive_form_holder.style.height = "28px";
      inactive_form_holder.style.width = "175px";
      let inactive_label = document.createElement("label");
      inactive_label.innerHTML = "Hamarosan megnyíló laborok";
      inactive_label.htmlFor = "map-inactive";
      inactive_form_holder.appendChild(inactive_label);
      let inactive_checkbox = document.createElement("input");
      inactive_checkbox.id = "map-inactive";
      inactive_checkbox.type = "checkbox";
      inactive_checkbox.checked = true;
      inactive_checkbox.addEventListener("change", (_) => {
        const inactive_pins = document.getElementsByClassName("inactive-pin");
        Array.from(inactive_pins).forEach((pin) => {
          pin.style.display = inactive_checkbox.checked ? "block" : "none";
        });
      });
      inactive_form_holder.appendChild(inactive_checkbox);
      inactive_holder.appendChild(inactive_form_holder);

      inactive_holder.addEventListener("mouseover", (_) => {
        img.style.display = "none";
        inactive_form_holder.style.display = "block";
      });

      inactive_holder.addEventListener("mouseout", (_) => {
        img.style.display = "block";
        inactive_form_holder.style.display = "none";
      });

      let control = document.createElement("div");
      control.appendChild(inactive_holder);

      map.controls[window.google.maps.ControlPosition.TOP_RIGHT].push(control);

      this.map = map;
      const { AdvancedMarkerElement, PinElement } =
        await loader.importLibrary("marker");
      (await API.query("labs")).forEach((lab) => {
        let markerPos = { lat: lab.lat, lng: lab.lng };
        let markerPin = new PinElement({
          glyphColor: lab.active ? "#F2F2F2" : "#949494",
          borderColor: lab.active ? "#f2f2f2" : "#616161",
          background: lab.active ? "#0080ff" : "#c2c2c2",
        }).element;
        markerPin.classList.add((lab.active ? "active" : "inactive") + "-pin");
        let marker = new AdvancedMarkerElement({
          map,
          position: markerPos,
          gmpClickable: true,
          content: markerPin,
          title: lab.name,
        });
        marker.addListener("click", () => {
          map.moveCamera({
            center: {
              lat: screen.width > 600 ? lab.lat : lab.lat - 0.001,
              lng: lab.lng,
            },
            zoom: 17,
          });
          this.props.on_lab_selected(lab.id);
        });
      });
    });
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    if (!nextProps.has_active_lab) {
      this.map.moveCamera(LabMap.default_view);
    }
    return false;
  }

  render() {
    return <div id="map" style={LabMap.style}></div>;
  }
}

export default LabMap;
