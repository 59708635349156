import React from "react";
import { createRoot } from "react-dom/client";
import { useState } from "@wordpress/element";
import { createTheme, MantineProvider, Button } from "@mantine/core";
import LabMap from "./components/map";
import InfoPanel from "./components/info_panel";

const theme = createTheme({});

const App = () => {
  const [labId, setLabId] = useState(0);

  return (
    <MantineProvider theme={theme}>
      <div style={{ position: "relative" }}>
        <LabMap
          on_lab_selected={(choosenLabId) => setLabId(choosenLabId)}
          has_active_lab={labId > 0}
          style={{ minHeight: "600px" }}
        />
        {labId > 0 && (
          <InfoPanel
            labId={labId}
            authenticated={labfinderParams.authenticated}
            onClose={() => setLabId(0)}
          />
        )}
      </div>
    </MantineProvider>
  );
};

window.addEventListener(
  "load",
  function () {
    document
      .querySelectorAll(".wp-block-cansatlab-labfinder .app-container")
      .forEach((blockDomElement) => {
        const root = createRoot(blockDomElement);
        root.render(<App />);
      });
  },
  false,
);
