import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  Tooltip,
  useMap,
  LayersControl,
  LayerGroup,
} from "react-leaflet";
import { Icon } from "leaflet";
import { Fragment } from "react";
import { useState, useEffect } from "@wordpress/element";
import { API } from "utils";

const LabMarker = ({ active, position, on_click, title }) => {
  const inactive_marker = new Icon({
    iconUrl: labfinderParams.pluginPath + "assets/marker-icon.png",
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
  });

  const tooltip = <Tooltip>{title}</Tooltip>;

  return active ? (
    <Marker
      position={position}
      eventHandlers={{
        click: on_click,
      }}
    >
      {tooltip}
    </Marker>
  ) : (
    <Marker
      position={position}
      eventHandlers={{
        click: on_click,
      }}
      icon={inactive_marker}
    >
      {tooltip}
    </Marker>
  );
};

const Map = ({ on_lab_selected, has_active_lab }) => {
  const style = {
    minHeight: "600px",
  };

  const [map, setMap] = useState(null);

  const zoomToLab = (pos) => {
    if (map === null) return;
    map.flyTo(pos, 16);
  };

  const default_view = {
    pos: [47, 20.56],
    zoom: 7,
  };

  if (!has_active_lab && map !== null) {
    map.flyTo(default_view.pos, default_view.zoom);
  }

  const [labs, setLabs] = useState([]);

  useEffect(() => {
    API.query("labs")
      .then((res) => setLabs(res))
      .catch((error) => console.log(error));
  }, []);

  const markers = [];
  const inactiveMarkers = [];
  if (labs !== null) {
    labs.forEach((lab, _, __) => {
      let marker = (
        <Fragment key={lab.id}>
          <LabMarker
            active={lab.active}
            position={[lab.lat, lab.lng]}
            on_click={() => {
              if (!lab.active) return;
              on_lab_selected(lab.id);
              let zoom_pos = [lab.lat, lab.lng];
              zoom_pos[0] -= 0.001;
              zoomToLab(zoom_pos);
            }}
            title={lab.name}
          />
        </Fragment>
      );
      if (lab.active) {
        markers.push(marker);
      } else {
        inactiveMarkers.push(marker);
      }
    });
  }

  return (
    <MapContainer
      center={default_view.pos}
      zoom={default_view.zoom}
      scrollWheelZoom={false}
      style={style}
      ref={setMap}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <LayersControl position="topright">
        <LayersControl.Overlay name="Nemsokára nyíló labor" checked>
          <LayerGroup>{inactiveMarkers}</LayerGroup>
        </LayersControl.Overlay>
      </LayersControl>
      {markers}
    </MapContainer>
  );
};

export default Map;
