const query = async (path) => {
  let resp = await fetch(labfinderParams.restRoot + "labfinder/" + path, {
    headers: {
      "X-WP-Nonce": labfinderParams.nonce,
    },
  });
  let text = await resp.text();

  if (!resp.ok) {
    throw new Error("Status: " + resp.status + ". Body: " + text);
  }

  return JSON.parse(text);
};

const query_wp = async (path) => {
  let resp = await fetch(labfinderParams.restRoot + "wp/v2/" + path, {
    headers: {
      "X-WP-Nonce": labfinderParams.nonce,
    },
  });
  let text = await resp.text();

  if (!resp.ok) {
    throw new Error("Status: " + resp.status + ". Body: " + text);
  }

  return JSON.parse(text);
};

const post = async (path, data) => {
  let resp = await fetch(labfinderParams.restRoot + "labfinder/" + path, {
    method: "POST",
    headers: {
      "X-WP-Nonce": labfinderParams.nonce,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  let text = await resp.text();

  if (!resp.ok) {
    throw new Error("Status: " + resp.status + ". Body: " + text);
  }

  return JSON.parse(text);
};

const put = async (path, data) => {
  let resp = await fetch(labfinderParams.restRoot + "labfinder/" + path, {
    method: "PUT",
    headers: {
      "X-WP-Nonce": labfinderParams.nonce,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  let text = await resp.text();
  if (!resp.ok) {
    throw new Error("Status: " + resp.status + ". Body: " + text);
  }
  return JSON.parse(text);
};

const del = async (path) => {
  let resp = await fetch(labfinderParams.restRoot + "labfinder/" + path, {
    method: "DELETE",
    headers: {
      "X-WP-Nonce": labfinderParams.nonce,
    },
  });
  let text = await resp.text();
  if (!resp.ok) {
    throw new Error("Status: " + resp.status + ". Body: " + text);
  }
  return JSON.parse(text);
};

export { query, put, post, del, query_wp };
