const API_KEY = "AIzaSyAuDXAs23HeTD4o3I98imkvWeNep3wvvTM";

export async function validateAddress(addressInput, region) {
  let resp = await fetch(
    "https://addressvalidation.googleapis.com/v1:validateAddress?key=" +
      API_KEY,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        address: {
          regionCode: region,
          addressLines: [addressInput],
        },
      }),
    },
  );

  let text = await resp.text();

  if (!resp.ok)
    return {
      type: "error",
      error: "request",
      status: resp.status,
      message: text,
    };
  let json = JSON.parse(text);
  let verdictInfo = json.result.verdict;
  if (!verdictInfo.addressComplete)
    return {
      type: "error",
      error: "invalid_addr",
      message: "The address isn't recongizable.",
    };
  let address = json.result.address.formattedAddress;
  let location = json.result.geocode.location;
  if (
    verdictInfo.addressComplete &&
    (verdictInfo.hasInferredComponents || verdictInfo.hasReplacedComponents)
  ) {
    return {
      type: "address",
      confirm: true,
      address: address,
      geocode: location,
    };
  }
  return {
    type: "address",
    confirm: false,
    address: address,
    geocode: location,
  };
}
