import { CloseButton, Container } from "@mantine/core";
import { useState, useEffect } from "@wordpress/element";
import { API } from "utils";
import "./info_panel.css";

const InfoPanel = ({ labId, authenticated, onClose }) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    API.query("lab/" + labId)
      .then((data) => setData(data))
      .catch((err) => console.error(err));
  }, [labId, authenticated]);

  const field_name_style = {
    fontWeight: "bold",
  };

  const pictures = [];
  if (data != null && data.pictures != null) {
    data.pictures.forEach((item, idx, _) => {
      pictures.push(
        <div className="lab-pic-container" key={idx}>
          <img src={item} className="lab-pic" />
        </div>,
      );
    });
  }

  return data == null ? (
    <div></div>
  ) : (
    <Container
      style={{
        position: "absolute",
        bottom: 0,
        left: 0,
        width: "350px",
        borderRadius: "0px 20px 0px 0",
        zIndex: 5000,
        paddingLeft: "15px",
        backgroundColor: "white",
      }}
      size="20rem"
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "15px",
        }}
      >
        <h1 style={{ fontSize: "30px", margin: 0 }}>{data.name}</h1>
        <CloseButton
          onClick={onClose}
          variant="transparent"
          styles={{
            root: {
              height: "5rem",
              width: "5rem",
              marginRight: "15px",
              background: "none",
              border: "none",
            },
            inner: { backgroundColor: "none" },
          }}
        />
      </div>
      {authenticated && (
        <>
          <div className="info_holder">
            <p>{data.address}</p>
            {data.description !== "" && <p>{data.description}</p>}
            {data.phone !== "" && (
              <p>
                <span style={field_name_style}>Telefonszám: </span>+{data.phone}
              </p>
            )}
            {data.email !== "" && (
              <p>
                <span style={field_name_style}>E-mail cím: </span>
                {data.email}
              </p>
            )}
          </div>
          {pictures.length > 0 && (
            <div>
              <h2
                style={{
                  margin: "0.5rem 0 0.25rem 0",
                  fontSize: "25px",
                  fontWeight: "bold",
                }}
              >
                Képek
              </h2>
              <div
                style={{
                  display: "flex",
                  marginBottom: "1rem",
                  overflowX: "auto",
                  width: "100%",
                  whiteSpace: "nowrap",
                }}
              >
                {pictures}
              </div>
            </div>
          )}
        </>
      )}
    </Container>
  );
};

export default InfoPanel;
